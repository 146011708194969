import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col, Button } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import {
  blockUser,
  getAllUsers,
  getAppointmentRequests,
} from "../../services/User/UserService";
import profile from "../../images/blank-profile-picture.webp";
import { notifyError, notifyTopRight } from "../common/Toaster";
import moment from "moment";

export default function AppointmentRequests(props) {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [type, setType] = useState("upComing");
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListlenght] = useState(0);
  const [search, setSearch] = useState("");
  const limit = 10;
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  ///function to fetch table data ///
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getAppointmentRequests(currentPage, limit, type);
      console.log(response.data.data.appointments);
      setUsers(response.data.data.appointments);
      const total = response.data.data?.total;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.data?.total);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [currentPage, type]);

  return (
    <div>
      <PageTitle activeMenu="Appointment Requests" motherMenu="Appointments" />
      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <select
                  className="form-control"
                  onChange={(e) => {setType(e.target.value);setCurrentPage(0)}}
                  value={type}
                >
                  <option hidden>Select</option>
                  <option value={"upComing"}>Upcoming</option>
                  <option value={"past"}>Past</option>
                </select>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            {loader ? (
              <Spinner />
            ) : (
              <Table responsive>
                <thead className="text-black">
                  <tr>
                    <th>
                      <strong>IMAGE</strong>
                    </th>
                    <th>
                      <strong>Patient Name</strong>
                    </th>
                    <th>
                      <strong>booking For</strong>
                    </th>
                    <th>
                      <strong>confirm Slot Time</strong>
                    </th>
                    <th>
                      <strong>method</strong>
                    </th>

                    <th>
                      <strong>STATUS</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((item, i) => (
                    <tr
                      key={i}
                      // onClick={() =>
                      //   props.history.push({
                      //     pathname: "/appointment-details",
                      //     state: item,
                      //   })
                      // }
                      className="pointer"
                    >
                      <td>
                        {item?.bookingFor === "mySelf" ? (
                          <img
                            src={
                              item?.user?.image
                                ? process.env.REACT_APP_BUCKET_BASE_URL +
                                  item?.user?.image
                                : profile
                            }
                            width={70}
                            height={70}
                          />
                        ) : (
                          <img
                            src={
                              item?.member?.image
                                ? process.env.REACT_APP_BUCKET_BASE_URL +
                                  item?.member?.image
                                : profile
                            }
                            width={70}
                            height={70}
                          />
                        )}
                      </td>
                      <td>
                        {item?.bookingFor == "familyMember"
                          ? item?.member?.name
                          : item?.user?.name}
                      </td>
                      <td>{item?.bookingFor}</td>
                      <td>
                        {moment(item?.confirmSlotTime).format("hh:mm A")},{" "}
                        {moment(item?.confirmSlotTime).format("DD/MM/YYYY")}
                      </td>
                      <td>{item?.method}</td>

                      <td>
                        {item?.isConfirm ? (
                          <Badge variant="success light">Confirm</Badge>
                        ) : (
                          <Badge variant="danger light">Reject</Badge>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {users?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            {users?.length !== 0 && (
              <div className="card-footer clearfix">
                <div className="d-block d-sm-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Showing {currentPage * limit + 1} to{" "}
                    {listLength > (currentPage + 1) * limit
                      ? (currentPage + 1) * limit
                      : listLength}{" "}
                    of {listLength} entries
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}
