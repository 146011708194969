import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAyV8eDkZpQiznCk5fQbAKSvsnebvO9_Ek",
  authDomain: "anytime-hospital.firebaseapp.com",
  projectId: "anytime-hospital",
  storageBucket: "anytime-hospital.firebasestorage.app",
  messagingSenderId: "26417133537",
  appId: "1:26417133537:web:b1e69a1322075f66f6c4a0",
  measurementId: "G-V91637E3NG",
};

const firebaseapp = initializeApp(firebaseConfig);

const messaging = () => getMessaging(firebaseapp);

export default messaging;
