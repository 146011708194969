export const GET_USERS = 'admin/users';

export const DELETE_USERS = 'admin/auth/userDelete';

export const BLOCK_USERS = 'admin/userAction';

export const APPROVE_REQUESTS = 'admin/actionDoctorsRequests';

export const GET_REQUESTS = 'admin/doctorsRequests';

export const GET_APPOINTMENT_REQUESTS = 'admin/getAppointments';

export const AVAILABILITY = 'admin/addAvailability';

export const GET_PAYMENT_REQUESTS = 'admin/paymentRequest';

export const ACTION_PAYMENT_REQUESTS = 'admin/actionOnPayment';


