/// auth service

export const LOGIN = 'admin/login';

export const CHANGE_PASSWORD = 'admin/chnagePassword';

export const ADD_AMOUNT = 'admin/addMoneyToWallet'

export const DEDUCT_AMOUNT = 'admin/deductMoneyToWallet';

export const SUB_ADMIN = 'admin/subAdmin';

export const LOGOUT = 'admin/logout';

export const DASHBOARD = 'admin/dashboard';

export const GET_NOTIFICATION_API = "user/auth/fcm"