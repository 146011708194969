const { useState, useEffect } = require("react")

const useNotificationPermission=()=>{
    const [permission,setPremission]=useState("default")

    useEffect(()=>{
        const handler =()=> setPremission(Notification.permission)
        handler()
        Notification.requestPermission().then(handler)

        navigator.permissions
        .query({name : "notifications"})
        .then((notificationPerm)=>{
            notificationPerm.onchange = handler;
        })
    },[])
    return  permission
}

export default useNotificationPermission