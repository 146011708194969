import React from "react";
import { Modal } from "react-bootstrap";
import crossIcon from "../../images/deleteIcon.svg";
import profile from "../../images/blank-profile-picture.webp";

export default function FullImage({ show, image, onHide }) {
  return (
    <Modal
      className="modal fade image-modal"
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Header className="border-0 w-100">
        <div className="image-container w-100" role="document">
          <div className="position-relative">
            <img
              src={crossIcon}
              alt="Cross Icon"
              className={`cross-icon pointer `}
              onClick={() => onHide()}
            />
            <img
              src={
                image ? process.env.REACT_APP_BUCKET_BASE_URL + image : profile
              }
              alt="img"
              className="w-100"
            />
          </div>
        </div>
      </Modal.Header>
    </Modal>
  );
}
