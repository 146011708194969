import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
//import logo from '../../images/logo-full.png'
import { ToastContainer, toast } from "react-toastify";
import { changePasswoard, postAddAmount } from "../../services/AuthService";
function AddAmountModel({ modelShow, userId, getTableData, onHide, props }) {
  //Modal box
  let errorsObj = { addAmount: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [apiError, setApiError] = useState();
  const [addAmount, setAddAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const notifyTopRight = () => {
    getTableData();
    toast.success("✅ Amount  added successfully !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  function onChangePassword(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (addAmount === "") {
      errorObj.addAmount = "Amount is required";
      error = true;
    } else if (isNaN(Number(addAmount)) || Number(addAmount) <= 0) {
      errorObj.addAmount = "Amount must be a positive number";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoading(true);
    postAddAmount(userId, addAmount)
      .then((response) => {
        setAddAmount("");
        onHide();
        notifyTopRight("Updated Successfully.");
        setLoading(false);
      })
      .catch((error) => {
        console.log("error");
        // setApiError(error.response.data.message);
        notifyError("Try again later");
        setLoading(false);
      });
  }
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal className="modal fade" show={modelShow}>
        <div className="" role="document">
          <div className="">
            <form onSubmit={onChangePassword}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Add Amount</h4>
                {/* <button type="button" className="btn-close"  data-dismiss="modal"><span></span></button> */}
              </div>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    {apiError && (
                      <div
                        role="alert"
                        className="fade alert-dismissible fade show alert alert-danger show"
                      >
                        {apiError}
                      </div>
                    )}
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Add Amount</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          value={addAmount}
                          onChange={(e) => setAddAmount(e.target.value)}
                          placeholder="Enter your Amount"
                        />
                        <span className="validation-text"></span>
                        {errors.addAmount && (
                          <div className="text-danger fs-12">
                            {errors.addAmount}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                  // onClick={onChangePassword}
                >
                  {loading ? "Loading..." : "Submit"}
                </button>
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(AddAmountModel);
