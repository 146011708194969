import { getToken, isSupported } from "firebase/messaging";
import { useEffect, useState } from "react";
import useNotificationPermission from "./useNotificationPermission";
import messaging from "../firebase/firebaseConfig";

const useFCMToken =()=>{
    const permission = useNotificationPermission()
    const [fcmToken,setFcmToken]=useState(null)

    useEffect(()=>{
        const retrieveToken=async()=>{
            if(typeof window !== "undefined" && "serviceWorker" in navigator){
                if(permission  === "granted"){
                    const isFCMSupported = await isSupported();
                    if(!isFCMSupported) return ;
                    const fcmToken = await getToken(messaging(),{
                        vapidKey:"BHQL8ecOZ2SW8ZkPYpDrJPS1MPHUkLkMaLghnRJZ1fIo_J4L3JCJ4awD2nEJREaMTwUQ8jyqNcZ2BatYXy-EnGw"
                    })
                    setFcmToken(fcmToken)
                }
            }
        }
        retrieveToken()
    },[permission])
    return fcmToken
}

export default useFCMToken 