import { useEffect, useRef, useState } from "react";
import { onMessage } from "firebase/messaging";
import useFCMToken from "./useFCMToken";
import Cookies from "js-cookie";
import messaging from "../firebase/firebaseConfig";
import { notifyTopRight } from "../jsx/common/Toaster";

const useFCM = () => {
  const fcmToken = useFCMToken();
  const [messages, setMessages] = useState([]);
  const token = localStorage.getItem("doctorToken");

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      const fcmmessaging = messaging();
      const unsubscribe = onMessage(fcmmessaging, (payload) => {
        notifyTopRight(`${payload.notification.title}`);
        setMessages((message) => [...message, payload]);
      });
      return () => unsubscribe();
    }
  }, [fcmToken,token]);
  return { fcmToken, messages };
};

export default useFCM;